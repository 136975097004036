
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import NaviBar from '@/components/NaviBar.vue'
import AudioPlayer from '@/components/AudioPlayer.vue'
import service from '@/utils/request'
import { Content, Practice } from '@/models/practice'
import { Toast } from 'vant'

@Options({
  components: {
    NaviBar,
    AudioPlayer
  }
})
export default class StandardPractice extends Vue {
  checked = false
  isPopShow = false
  isInfoShow = false
  isPlay = false
  practiceId = ''
  startTime = 0
  endTime = 0
  currentIndex = 0
  taskId = ''
  isDaily = '0'
  imageIndex = 0
  imageTime = 0

  practice: Practice = {
    collectionId: '',
    content: [],
    cover: '',
    id: 0,
    introduction: '',
    isCollected: false,
    name: '',
    resourceType: '',
    type: '',
    isDailyPractice: false
  }

  get getCurrentImage () {
    return require('../assets/bofangqi/p_' + (this.imageIndex % 49 + 0) + '.png')
  }

  clockStart () {
    this.imageTime = setInterval(() => {
      this.imageIndex += 1
    }, 40)
  }

  clockEnd () {
    clearInterval(this.imageTime)
  }

  mounted () {
    this.practiceId = this.$route.params.practiceId as string
    this.taskId = this.$route.params.taskId as string
    console.log('this.taskId === ', this.taskId)
    console.log('this.practiceId === ', this.practiceId)
    this.loadData()
  }

  loadData () {
    service.get('/mindup/mini/practice/' + this.practiceId, {}).then(res => {
      this.practice = res.data
      this.checked = res.data.isDailyPractice
    })
  }

  tapInfo () {
    this.isPopShow = false
    this.isInfoShow = true
  }

  tapCollect () {
    // 收藏
    service.post('/mindup/mini/practice/' + this.practiceId + '/collect-or-cancel', {}).then(res => {
      // this.practice.isCollected = res.data.isCollected
    })
  }

  tapDaily () {
    service.post('/mindup/mini/practice/' + this.practiceId + '/daily-practice/add-or-cancel', {}).then(res => {
      this.practice.isDailyPractice = res.data.isDailyPractice
    })
  }

  tapClose () {
    this.isPopShow = false
  }

  tapVersionBtn (index: number) {
    this.$nextTick(() => {
      this.currentIndex = index
    })
    // this.replay()
  }

  tapSetting () {
    this.isPopShow = true
  }

  replay () {
    (this.$refs.audio as AudioPlayer).pause()
    this.clockEnd()
    this.isPlay = false
  }

  tapPlay () {
    if (this.isPlay) {
      (this.$refs.audio as AudioPlayer).pause()
      this.clockEnd()
    } else {
      (this.$refs.audio as AudioPlayer).play()
      this.clockStart()
      this.playRequest()
      if (this.startTime === 0) {
        console.log('this.startTime')
        this.startTime = Date.now()
      }
    }
    this.isPlay = !this.isPlay
  }

  playRequest () {
    service.post('/mindup/mini/practice/' + this.practiceId + '/practice-played-log/add')
  }

  audioStop () {
    this.$nextTick(() => {
      this.isPlay = false
    })
  }

  audioPlay () {
    this.$nextTick(() => {
      this.isPlay = true
    })
  }

  audioStart () {
    if (this.startTime === 0) {
      console.log('this.startTime')
      this.startTime = Date.now()
    }
  }

  audioEnd (e: any) {
    this.isPlay = false
    if (this.endTime === 0) {
      this.endTime = Date.now()
    }
    // 计算差值
    const time = (this.endTime - this.startTime) / 1000
    const isComplete = time / e.duration > 0.7 ? 1 : 0

    service.post('/mindup/mini/tasks/' + this.taskId + '/practice-task/complete', {
      exerciseDuration: time,
      isStandard: isComplete
    }).then(res => {
      Toast.success({
        message: '练习完成',
        duration: 2000
      })

      setTimeout(() => {
        this.$router.push({
          name: 'DiaryWrite',
          params: {
            isComplete: isComplete,
            taskId: this.taskId,
            isDaily: this.isDaily
          }
        })
      }, 2000)
    })
  }
}
