
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {}
})
export default class AudioPlayer extends Vue {
  @Prop() src = ''
  currentProgress = 0
  currentTime = 0
  duration = 0
  currentTimeStr = '00:00'
  durationStr = '00:00'

  firstPlay () {
    (this.$refs.audio as HTMLMediaElement).play()
  }

  play () {
    (this.$refs.audio as HTMLMediaElement).play()
    this.$emit('play')
  }

  load () {
    (this.$refs.audio as HTMLMediaElement).load()
  }

  pause () {
    (this.$refs.audio as HTMLMediaElement).pause()
    this.$emit('stop')
  }

  seek (num: number) {
    (this.$refs.audio as HTMLMediaElement).currentTime = num
    this.play()
  }

  loadedmetadata (data: any) {
    console.log(data)
    this.currentTime = (this.$refs.audio as HTMLMediaElement).currentTime
    this.duration = (this.$refs.audio as HTMLMediaElement).duration
    this.durationStr = this.fixTo(this.duration / 60) + ':' + this.fixTo(this.duration % 60)
  }

  ended () {
    console.log('end')
    this.$emit('end', { duration: (this.$refs.audio as HTMLMediaElement).duration })
  }

  timeupdate (time: any) {
    this.currentTime = (this.$refs.audio as HTMLMediaElement).currentTime
    this.currentTimeStr = this.fixTo(this.currentTime / 60) + ':' + this.fixTo(this.currentTime % 60)
    this.currentProgress = (this.currentTime / this.duration * 100)
    // console.log(this.currentProgress)
  }

  fixTo (num: number) {
    if (num < 10) {
      return '0' + parseInt(num.toString())
    } else {
      return parseInt(num.toString()) + ''
    }
  }

  dargStart () {
    console.log('start')
    this.pause()
  }

  dargEnd () {
    // const currentSeek = this.currentProgress * this.duration / 100
    // console.log('end', currentSeek)
    // this.seek(currentSeek)
  }

  change (value: any) {
    console.log('change == ', value)
    const currentSeek = this.currentProgress * this.duration / 100
    this.seek(currentSeek)
    this.$emit('start')
  }

  update (value: any) {
    // console.log('update == ', value)
    // const currentSeek = this.currentProgress * this.duration / 100
  }
}
